import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import BaseBlocksRenderer from "../components/blocks/base-blocks-renderer"

const SobreVoltioPage = () => {
  const { strapiAboutVoltio } = useStaticQuery(graphql`
    query {
      strapiAboutVoltio {
        defaultSeo {
          metaTitle
          metaDescription
          shareImage {
            id
            url
            localFile {
              url
            }
          }
        }
        blocks {
          __typename
          ... on STRAPI__COMPONENT_SHARED_HEADER {
            title
            cols
            cover {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
            richTextBody: body {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          ...BaseSectionTitle
          ... on STRAPI__COMPONENT_SHARED_NESTED_ICON_CARD {
            columns
            cards {
              id
              iconSubtitle
              hasBg
              fixedHeight
              cover {
                alternativeText
                url
              }
            }
          }
          ... on STRAPI__COMPONENT_SHARED_IMAGE_DESCRIPTION {
            image {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            richTextBody: description {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo seo={strapiAboutVoltio.defaultSeo} />
      <BaseBlocksRenderer blocks={strapiAboutVoltio.blocks} />
    </Layout>
  )
}

export default SobreVoltioPage
